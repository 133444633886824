import axios from '@/utils/axios'
// -------------------------- 市场 ---------------------------
// 市场列表
export const getMarketLsit = params => axios({
  method: 'get',
  url: '/admin/shop/readAdminMarketList',
  params
}).then(res => res.data)

// -------------------------- 店铺 ---------------------------

// 列表
export const getShopList = params => axios({
  method: 'get',
  url: '/admin/shop/readAdminShopList',
  params
}).then(res => res.data)

// 导出
export const shopExport = data =>
  axios.post('/admin/shop/exportAdminShopList', data)
    .then(res => res)

// 添加
export const createShop = data =>
  axios.post('/admin/shop/createAdminShopResource', data)
    .then(res => res)

// 更新
export const updateShop = data =>
  axios.post('/admin/shop/updateAdminShopResource', data)
    .then(res => res)

// 市场列表
export const readAdminMarketList = params => axios({
  method: 'get',
  url: '/admin/market/readAdminMarketList?page=1&limit=100000',
  params
}).then(res => res.data)

// 类型列表
export const readAdminShopTypeList = params => axios({
  method: 'get',
  url: '/admin/shopType/readAdminShopTypeList?page=1&limit=1000000',
  params
}).then(res => res.data)

// 详情
export const shopDetail = params => axios({
  method: 'get',
  url: '/admin/shop/readAdminShopInfo',
  params
}).then(res => res.data)

// 订单状态修改
export const checkOrder = data =>
  axios.post('/shop/order/checkOrder', data)
    .then(res => res)

// 服务列表
export const readServiceList = data =>
  axios.post('/crm/customer/readServiceList', data)
    .then(res => res.data)

// 服务列表导出
export const exportService = data =>
  axios.post('/crm/customer/readServiceList_export', data)
    .then(res => res)


// -------------------------- 仓库 ---------------------------

// 列表
export const readAdminStoreList = params => axios({
  method: 'get',
  url: '/admin/Store/readAdminStoreList',
  params
}).then(res => res.data)

// 添加
export const createShopAdminStoreResource = data =>
  axios.post('/admin/Store/createShopAdminStoreResource', data)
    .then(res => res)

// 更新
export const updateShopAdminStoreResource = data =>
  axios.put('/admin/Store/updateShopAdminStoreResource', data)
    .then(res => res)

// 详情
export const readShopAdminStoreInfo = params => axios({
  method: 'get',
  url: '/admin/Store/readShopAdminStoreInfo',
  params
}).then(res => res.data)

// -------------------------- 大区 ---------------------------

// 列表
export const readAdminRegionList = params => axios({
  method: 'get',
  url: '/admin/region/readAdminRegionList',
  params
}).then(res => res.data)

// 添加
export const createShopAdminRegionResource = data =>
  axios.post('/admin/region/createShopAdminRegionResource', data)
    .then(res => res)

// 更新
export const updateShopAdminRegionResource = data =>
  axios.post('/admin/region/updateShopAdminRegionResource', data)
    .then(res => res)

// 详情
export const readShopAdminRegionInfo = params => axios({
  method: 'get',
  url: '/admin/region/readShopAdminRegionInfo',
  params
}).then(res => res.data)

// -------------------------- 市场 ---------------------------

// 列表
export const marketList = params => axios({
  method: 'get',
  url: '/admin/market/readAdminMarketList',
  params
}).then(res => res.data)

// 添加
export const createShopAdminMarketResource = data =>
  axios.post('/admin/market/createShopAdminMarketResource', data)
    .then(res => res)

// 更新
export const updateAdminShopResource = data =>
  axios.post('/admin/market/updateAdminShopResource', data)
    .then(res => res)

// 详情
export const readShopAdminMarketInfo = params => axios({
  method: 'get',
  url: '/admin/market/readShopAdminMarketInfo',
  params
}).then(res => res.data)

// 根据店铺获取所在市场及关联市场列表
export const shopMarket = params => axios({
  method: 'get',
  url: '/admin/shop/readAdminShopRelationMarketByShopId',
  params
}).then(res => res.data)

// 根据店铺获取所在仓库及关联仓库列表
export const shopStore = params => axios({
  method: 'get',
  url: '/admin/Store/readAdminStoreWithMarketListByShopId',
  params
}).then(res => res.data)

// -------------------------- 获取登陆人信息 ---------------------------

// 登陆人详情
export const getUserInfo = params => axios({
  method: 'get',
  url: '/admin/shop/readAdminUserInfo',
  params
}).then(res => res.data)

// -------------------------- 疤痕计算器 ---------------------------
export const getScarReckoner = params => axios({
  method: 'get',
  url: '/tool/scarReckoner/getIndex',
  params
}).then(res => res.data)

export const getSecondType = params => axios({
  method: 'get',
  url: '/tool/scarReckoner/getSecond',
  params
}).then(res => res.data)

export const scarReckonerSubmit = params => axios({
  method: 'get',
  url: '/tool/scarReckoner/scarReckonerSubmit',
  params
}).then(res => res.data)
// -------------------------- 素材库 ---------------------------
export const materialUpload = data =>
  axios.post('/shop/material/upload', data)
    .then(res => res)
export const uploadComplete = data =>
  axios.post('/shop/material/uploadComplete', data)
    .then(res => res)
export const saveFileLog = data =>
  axios.post('/shop/material/saveFileLog', data)
    .then(res => res)
export const getFile = data =>
  axios.post('/shop/material/getFile', data)
    .then(res => res)
export const downFile = data =>
  axios.post('/shop/material/downFile', data)
    .then(res => res)
export const getTree = params => axios({
  method: 'get',
  url: '/shop/material/getTree',
  params
}).then(res => res.data)
export const deleteFile = data =>
  axios.post('/shop/material/deleteFile', data)
    .then(res => res)
// export const getFile = params => axios({
//   method: 'get',
//   url: '/shop/material/getFile',
//   params
// }).then(res => res.data)
