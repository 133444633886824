import axios from '@/utils/axios'

// 订单列表
export const orderList = params => axios({
    method: 'get',
    url: '/shop/order/index',
    params
  }).then(res => res.data)

// 销售出库单列表锁定
export const lockOrder = data =>
  axios.post('/shop/order/lockOrder', data)
    .then(res => res)

// 收款 退款 退货单锁定
export const lockOrderR = data =>
  axios.post('/shop/refund/lockOrder', data)
    .then(res => res)

// 订单主要详情
export const orderDetail = params => axios({
  method: 'get',
  url: '/shop/order/detail',
  params
}).then(res => res.data)

// 订单商品详情
export const orderGoodsDetail = params => axios({
  method: 'get',
  url: '/shop/order/orderGoodsDetail',
  params
}).then(res => res.data)

// 订单商品护理师及服务小项变更详情
export const orderGoodsOptionDetail = params => axios({
  method: 'get',
  url: '/shop/order/orderGoodsOptionDetail',
  params
}).then(res => res.data)

// 添加订单
export const createOrder = data =>
  axios.post('/shop/order/create', data)
    .then(res => res)

// 修改销售订单
export const orderUpdateDetail = data =>
axios.post('/shop/order/updateDetail', data)
  .then(res => res)


// 订单下推接口
export const orderPush = data =>
  axios.post('/shop/order/orderPush', data)
    .then(res => res)

// 作废订单
export const checkOrder = data =>
  axios.post('/shop/order/checkOrder', data)
    .then(res => res)

// 导出订单
export const exportOrder = data =>
  axios.post('/shop/order/export', data)
    .then(res => res)

  // 退款订单列表
export const refundList = params => axios({
  method: 'get',
  url: '/shop/refund/index',
  params
}).then(res => res.data)

  // 退款订单导出
export const refundExport = data =>
  axios.post('/shop/refund/export', data)
    .then(res => res)

// 退款结算方式列表
export const refundMode = data =>
  axios.post('/shop/refund/refundMode', data)
    .then(res => res)

// 退款订单创建
export const refundOrder = data =>
  axios.post('/shop/refund/createOrder', data)
    .then(res => res)
	
// 修改订单
export const updateDetail = data =>
axios.post('/shop/refund/updateDetail', data)
  .then(res => res)

// 销售政策列表
export const saleList = data =>
  axios.post('/shop/order/saleList', data)
    .then(res => res)

// 未支付收款单列表
export const unpaidList = params => axios({
  method: 'get',
  url: '/shop/refund/unpaidList',
  params
}).then(res => res.data)


// 订单获取第二个门店
export const getTsShopList = data =>
axios.post('/crm/customer/getTsShopList', data)
  .then(res => res)

// 退款订单主要详情
export const refundDetail = params => axios({
  method: 'get',
  url: '/shop/refund/detail',
  params
}).then(res => res.data)

// 退款订单详情
export const orderRefundDetail = params => axios({
  method: 'get',
  url: '/shop/refund/orderGoodsDetail',
  params
}).then(res => res.data)

// 退货订单创建
export const delRefundOrder = data =>
  axios.post('/shop/refund/checkOrder', data)
    .then(res => res)

// 退货订单创建
export const createRefundGoods = data =>
  axios.post('/shop/refund/createRefundGoods', data)
    .then(res => res)

// 仓库列表
export const houseList = params => axios({
  method: 'get',
  url: '/shop/order/houseList',
  params
}).then(res => res.data)

// 计算订单金额
export const getPayment = data =>
  axios.post('/crm/customer/getPayment', data)
    .then(res => res)

// 客户的优惠券
export const getCouponProvide = data =>
  axios.post('/crm/customer/getCouponProvide', data)
    .then(res => res)

//-----------------------转诊业绩-------------------
export const addTurnRefund = data =>
  axios.post('/shop/turnRefundC/addTurnRefund', data)
    .then(res => res)

// 销售政策列表
export const turnPerformanceList = params => axios({
  method: 'get',
  url: '/shop/turnRefundC/turnPerformanceList',
  params
}).then(res => res.data)

//-----------------------销售政策-------------------

// 销售政策列表
export const policyList = params => axios({
  method: 'get',
  url: '/admin/policy/index',
  params
}).then(res => res.data)

// 新增销售政策
export const createPolicy = data =>
  axios.post('/admin/policy/create', data)
    .then(res => res)

// 更新销售政策
export const updatePolicy = data =>
  axios.post('/admin/policy/update', data)
    .then(res => res)

//-----------------------兑换订单-------------------

// 兑换订单信息
export const getCouponProvideCustomer = data =>
  axios.post('/crm/customer/getCouponProvideCustomer', data)
    .then(res => res)

// 核销兑换优惠券
export const createYhjOrder = data =>
  axios.post('/crm/customer/createYhjOrder', data)
    .then(res => res)
