import axios from '@/utils/axios'

// 员工职位列表
export const readAdminPositionList = params => axios({
  method: 'get',
  url: '/admin/position/readAdminPositionList?page=1&limit=1000000',
  params
}).then(res => res.data)

// 员工岗位列表
export const readAdminStationList = params => axios({
  method: 'get',
  url: '/admin/station/readAdminStationList?page=1&limit=1000000',
  params
}).then(res => res.data)

// 员工详情
export const staffDetail = params => axios({
  method: 'get',
  url: '/admin/person/readAdminPersonInfo',
  params
}).then(res => res.data)

// 员工列表导出
export const staffExport = data =>
  axios.post('/admin/person/exportAdminPersonList', data)
    .then(res => res)

// 员工评分导出
export const getperson = data =>
  axios.post('/crm/customerTj/getperson', data)
    .then(res => res)

// 员工列表
export const staffList = data =>
  axios.post('/admin/person/readAdminShopPersonList', data)
    .then(res => res.data)

// 订单员工列表S
export const staffListS = data =>
  axios.post('/admin/person/readAdminShopPersonListByRefundOrder', data)
    .then(res => res.data)

// 添加员工
export const createStaff = data =>
  axios.post('/admin/person/createAdminShopPersonResource', data)
    .then(res => res)
  
// 编辑商品
export const updateStaff = data =>
  axios.post('/admin/person/updateAdminShopResource', data)
    .then(res => res)
    
// 获取门店员工列表
export const getStaffList = params => axios({
  method: 'get',
  url: '/admin/staff/readAdminStaffList',
  params
}).then(res => res.data)

// 获取门店员工列表
export const getMonth = params => axios({
  method: 'get',
  url: '/mini/indexCustomer/getMonthDays',
  params
}).then(res => res.data)

// // 获取员工排班列表
// export const scheduleList = params => axios({
//   method: 'get',
//   url: '/mini/indexCustomer/scheduleList',
//   params
// }).then(res => res.data)

// 获取员工排班列表
export const scheduleList = data =>
  axios.post('/mini/indexCustomer/scheduleList', data)
    .then(res => res)

// 设置员工排班
export const setSchedule = data =>
  axios.post('/mini/indexCustomer/setSchedule', data)
    .then(res => res)
// 设置员工排班一周
export const setScheduleWeek = data =>
  axios.post('/mini/indexCustomer/setScheduleWeek', data)
    .then(res => res)
// 获取代理店长列表
export const getAgentList = params => axios({
  method: 'get',
  url: '/admin/person/getAgentList',
  params
}).then(res => res.data)
//店铺列表
export const getAgentShopList = params => axios({
  method: 'get',
  url: '/admin/person/getAgentShopList',
  params
}).then(res => res.data)
// 设置代理店长店铺
export const setShopAgent = data =>
  axios.post('/admin/person/setShopAgent', data)
    .then(res => res)

// 获取员工排班列表
export const getStaffBaoyou = data =>
  axios.post('/crm/staff/staffBaoyou', data)
    .then(res => res)