<template>
  <div class="batchBox">
    <div class="return" v-if="detail.uid" @click="back">
      <img class="backImg" src="@/assets/images/back.png" alt />
    </div>
    <el-form
      ref="detail"
      label-position="right"
      label-width="180px"
      :rules="rules"
      :model="detail"
      size="mini"
      class="formBox"
    >
      <el-form-item label="销售部门:">
        <el-input v-model="detail.shop_name" class="w200" readonly></el-input>

      </el-form-item>
      <el-form-item label="客户姓名:">
        <el-input v-model="detail.consignee" class="w200" readonly></el-input>
      </el-form-item>
      <el-form-item size="mini" label="开单人 " prop="sale_id">
        <el-select v-model="detail.sale_id" class="w200" filterable clearable @change="changeSale">
          <el-option
            v-for="item in options.staff"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="成交日期:" prop="deal_time">
        <el-date-picker
          v-model="detail.deal_time"
          type="date"
          class="w200"
          value-format="yyyy-MM-dd"
          placeholder="请选择业务日期"
          :picker-options="maxTime"
        />
      </el-form-item>
      <el-form-item label="结算组织">
        <el-select
          v-model="detail.sales_organization"
          @change="changeSales"
          placeholder="请选择"
          class="w200"
        >
          <el-option
            v-for="item in options.market"
            :key="item.marketKingdeeCode"
            :label="item.marketName"
            :value="item.marketKingdeeCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="收款组织">
        <el-select
          v-model="detail.settlement_organization"
          @change="changeSettlement"
          placeholder="请选择"
          class="w200"
        >
          <el-option
            v-for="item in options.market"
            :key="item.marketKingdeeCode"
            :label="item.marketName"
            :value="item.marketKingdeeCode"
          />
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="业务类型:" prop="business_type">
        <el-select v-model="detail.business_type" class="w200" clearable placeholder="请选择业务类型" @change="business_type" >
          <el-option
            v-for="item in options.business_type"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="业务类型分类:" v-show = "isShowBusinessTwoType">
        <el-select v-model="detail.business_two_type" class="w200" clearable placeholder="业务类型分类" >
          <el-option
            v-for="item in options.business_two_type"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item label="订单类型:" prop="order_type">
        <el-select v-model="detail.order_type" class="w200" clearable placeholder="请选择订单类型">
          <el-option
            v-for="item in options.order_type"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

	  <el-form-item size="mini" label="双美类型" v-show="is_show_order_class">
	    <el-radio v-model="detail.order_class" label="1">生美</el-radio>
	    <el-radio v-model="detail.order_class" label="2">医美</el-radio>
	  </el-form-item>

      <el-form-item label="收款类型:" prop="refund_type">
        <el-select
         v-model="detail.refund_type"
          class="w200"
          clearable
          placeholder="请选择收款类型"
          @change="changeRefundType"
          >
          <el-option
            v-for="item in options.refund_type"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>



      <el-form-item label="促销政策:">
        <el-select v-model="detail.sales_policy" filterable class="w200" clearable>
          <el-option
            v-for="item in options.sales_policy"
            :key="item.sales_policy"
            :label="item.sales_policy_name"
            :value="item.sales_policy"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        size="mini"
        label="转诊类型:"
        v-if="isSichuan == 1 && shopType == 2 && (detail.refund_type == '101.02' && turnShop )"
        prop="is_treatment"
      >
        <el-radio-group v-model="detail.is_treatment" @change="changeTreatment">
          <el-radio label="2">非疗程</el-radio>
          <el-radio label="1">疗程</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        size="mini"
        label="转诊类型:"
        v-if="isSichuan == 1 && shopType == 1 && detail.refund_type == '101.02' "
        prop="is_treatment"
      >
        <el-radio-group v-model="detail.is_treatment" @change="changeTreatment">
          <el-radio label="2">非疗程</el-radio>
          <el-radio label="1">疗程</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="转诊门店:"
        v-if="isSichuan == 1 && shopType == 1 && detail.is_treatment==2 && (detail.refund_type == '101.02' && !turnShop )"
      >
        <el-select
          v-model="detail.referral_shop_id"
          filterable
          class="w200"
          clearable
          placeholder="请输入转诊门店"
          @change="changeReferralShop"
        >
          <el-option
            v-for="item in options.referral_shop_id"
            :key="item.shop_id"
            :label="item.shop_name"
            :value="item.shop_id"
          />
        </el-select>
      </el-form-item>

      <el-form-item size="mini" label="是否体验">
        <el-radio v-model="detail.is_experience" label="0">否</el-radio>
        <el-radio v-model="detail.is_experience" label="1">是</el-radio>
      </el-form-item>
      <el-form-item
        v-if="detail.is_experience == 1"
        size="mini"
        label=" 体  验  人 "
        prop="experience_id"
      >
        <el-select
          v-model="detail.experience_id"
          class="w200"
          filterable
          clearable
          @change="changeExperiencc"
        >
          <el-option
            v-for="item in options.staff"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item size="mini" label="是否外联">
        <el-radio v-model="detail.is_outreach" label="0">否</el-radio>
        <el-radio v-model="detail.is_outreach" label="1">是</el-radio>
      </el-form-item>
      <el-form-item v-if="detail.is_outreach == 1" size="mini" label=" 外 联 人 " prop="outreahc_id">
        <el-select
          v-model="detail.outreahc_id"
          class="w200"
          filterable
          clearable
          @change="changeOutreahc"
        >
          <el-option
            v-for="item in options.staff"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item size="mini" label="是否转介绍">
        <el-radio v-model="detail.is_introduction" label="0">否</el-radio>
        <el-radio v-model="detail.is_introduction" label="1">是</el-radio>
      </el-form-item>
      <el-form-item
        v-if="detail.is_introduction == 1"
        size="mini"
        label="转介绍人 "
        prop="introduction_id"
      >
        <el-select
          v-model="detail.introduction_id"
          class="w200"
          filterable
          clearable
          @change="changeIntroduction"
        >
          <el-option
            v-for="item in options.staff"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="订单描述:">
        <el-input v-model="detail.description" class="w200" placeholder="请填写订单描述" type="textarea" />
      </el-form-item>
      <el-form-item
        v-if="userShopType == 1"
        label="选择商户:"
      >
        <el-radio v-model="detail.shanghu_type" label="1">公户</el-radio>
        <!-- <el-radio v-model="detail.shanghu_type" label="2">私户</el-radio> -->
      </el-form-item>

      <el-form-item
        v-if="detail.refund_type == '101.02'"
        label="收款方式:"
      >
        <el-select
         v-model="detail.refund_type_plus"
         class="w200"
         clearable placeholder="请选择收款方式"
         @change="changeRefundMode"
         >
          <el-option
            v-for="item in options.refund_type_plus"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <div class="center">
        <el-form-item label="收款单明细:">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            :disabled="detail.goods_type == 0"
            @click="handleProduct('add')"
          >新增</el-button>
        </el-form-item>
      </div>
      <div class="center">
        <el-table
          :data="goods"
          size="small"
          class="mb20"
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <el-table-column label="* 业务类型">
            <template slot-scope="scope">
              <el-select
                v-model="goods[scope.$index].business_type"
                size="mini"
                value-key="id"
                placeholder="请选择业务类型"
                @change="(value) => handleChange(value, scope.$index)"
                filterable
              >
                <el-option
                  v-for="(item, index) in options.business_type"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </template>
          </el-table-column>

          <el-table-column label="* 项目二级分类">
            <template slot-scope="{ $index }">
              <el-select
                v-model="goods[$index].business_two_type"
                size="mini"
                value-key="id"
                placeholder="项目二级分类"
               :disabled="goods[$index].business_type ==2 ?false:true"
                filterable
              >
                <el-option
                  v-for="(item, index) in options.business_two_type"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </template>
          </el-table-column>

          <el-table-column label="* 结算方式">
            <template slot-scope="{ $index }">
              <el-select
                v-model="goods[$index].refund_mode"
                size="mini"
                value-key="id"
                placeholder="请选择收款方式"
                filterable
              >
                <el-option
                  v-for="(item, index) in options.refundMode"
                  :key="index"
                  :label="item.refund_mode_name"
                  :value="item.refund_mode"
                />
              </el-select>
            </template>
          </el-table-column>

          <el-table-column label="* 本金金额(元)">
            <template slot-scope="{ $index }">
              <el-input-number
                v-model="goods[$index].pay_price"
                :min="0.0"
                :precision="2"
                size="mini"
                @change="(value) => payChange(value, $index)"
              />
            </template>
          </el-table-column>
          <el-table-column label="* 赠送金额(元)">
            <template slot-scope="{ $index }">
              <el-input-number
                v-model="goods[$index].gift_price"
                :min="0.0"
                :precision="2"
                size="mini"
                @change="(value) => presentChange(value, $index)"
              />
            </template>
          </el-table-column>

          <el-table-column label="*店转金额(元)">
            <template slot-scope="{ $index }">
              <el-input-number
                v-model="goods[$index].shop_transfer_price"
                :min="0.0"
                :precision="2"
                size="mini"
                @change="(value) => storeTransferChange(value, $index)"
              />
            </template>
          </el-table-column>

          <el-table-column label="* 我方银行账号">
            <template slot-scope="{ row }">
              <span v-if="row.bank == 1">{{ row.bank_number }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="70">
            <template slot-scope="{ row }">
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="clickDel(row,$index)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="center" style="text-align: right">
        <span class="fwb">金额合计：</span>
        <span >实收金额：{{ detail.pay_amount +  detail.shop_transfer_amount}} </span>
        <span class="ml20">本金：{{ detail.pay_amount }}</span>
        <span class="ml20">赠金：{{ detail.gift_amount }}</span>
        <span class="ml20">店转：{{ detail.shop_transfer_amount }}</span>
      </div>
      <div class="btnBox" style>
        <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('detail')">完成，提交</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { staffList } from "@/api/staff";
import { shopDetail, shopMarket, getUserInfo } from "@/api/shop";
import { refundMode, refundOrder, saleList, getTsShopList } from "@/api/order";
class Detail {
  type = 3;
  shop_id = ""; //店铺id
  shop_name = ""; //店铺名称
  uid = ""; //客户id
  consignee = ""; //客户名称
  bank_number = ""; //店铺银行卡号
  market_id = ""; //市场id
  market_name = ""; //市场名称
  sale_id = ""; //开单人id
  sale_name = ""; //开单人名称
  sales_organization = ""; // 销售组织编码
  sales_name = ""; // 销售组织名称
  settlement_organization = ""; // 发货组织编码
  settlement_name = ""; // 发货组织名称
  is_experience = "0"; // 是否体验
  experience_id = ""; // 体验人id
  experience_name = ""; // 体验人姓名
  is_outreach = "0"; // 是否外联
  outreahc_id = ""; // 外联人id
  outreahc_name = ""; // 外联人姓名
  is_introduction = "0"; // 是否转介绍
  introduction_id = ""; // 转介绍id
  introduction_name = ""; // 转介绍姓名
  order_type = ""; //订单类型
  refund_type = ""; //收款类型
  deal_time = "";
  sales_policy = "";
  referral_shop_id = "";
  referral_shop_name = "";
  is_treatment = "2";
  business_type = "";
  description = "";
  shanghu_type = "2"; // 商户分类
  refund_type_plus = "1"; // 收款类型2
  order_class = "0";//订单分类  //1 生美 2 医美
  business_two_type = "";//业务类型二级分类

  pay_amount = 0;
  gift_amount = 0;
  shop_transfer_amount = 0;
  total_amount = 0;

  goodsList = [];
}
class Rules {
  sale_id = [{ required: true, message: "请选择开单人", trigger: "change" }];
  sales_organization = [
    { required: true, message: "请选择销售组织", trigger: "change" }
  ];
  settlement_organization = [
    { required: true, message: "请选择发货组织", trigger: "change" }
  ];
  business_type = [
    { required: true, message: "请选择业务类型", trigger: "change" }
  ];
  order_type = [
    { required: true, message: "请选择订单类型", trigger: "change" }
  ];
  refund_type = [
    { required: true, message: "请选择收款类型", trigger: "change" }
  ];
  is_treatment = [
    { required: true, message: "请选择转诊类型", trigger: "change" }
  ];
  deal_time = [
    { required: true, message: "请选择业务日期", trigger: "change" }
  ];
  experience_id = [
    { required: true, message: "请选择体验人", trigger: "change" }
  ];
  outreahc_id = [
    { required: true, message: "请选择外联人", trigger: "change" }
  ];
  introduction_id = [
    { required: true, message: "请选择转介绍人", trigger: "change" }
  ];
  refund_type_plus = [
    { required: true, message: "请选择收款类型2", trigger: "change" }
  ];
}
class Options {
  constructor() {
    this.uid = [];
    this.shop_id = [];
    this.market = [];
    this.business_type = [
      { id: 1, name: "疤痕" },
      { id: 2, name: "项目" },
      { id: 3, name: "青春痘" },
      { id: 4, name: "护肤品" }
    ];
    this.order_type = [
      { id: 1, name: "新单" },
      { id: 2, name: "补单" }
    ];
    this.refund_type = [
      { id: "101.02", name: "收款" },
      { id: "101.03", name: "收款调整" }
    ];

    this.refund_type_plus = [
      { id: "1", name: "通联支付" },
      { id: "2", name: "传统支付" },
      { id: "3", name: "通联远程" }
    ];

    this.business_two_type = [
      { id: 1, name: "身体项目" },
      { id: 2, name: "皮肤项目" },
    ];
    this.outreahc_id = [];
    this.staff = [];
    this.refundMode = [];
    this.sales_policy = [];
    this.referral_shop_id = [];
  }
}
class Goods {
  id = "";
  name = "";
  goods_sn = "";
  business_two_type= '';
  bank_number = '';
  refund_mode = '';
}
export default {
  data() {
    return {
      detail: new Detail(),
      rules: new Rules(),
      goods: [new Goods()], // 已选列表
      options: new Options(), // 选项
      loading: false,
      turnShop: false, // 店转
      isSichuan: 1, //川渝地区 默认否
      shopType: 2, //门店门诊 默认门店
      userShopType: '', //门店门诊 默认门店
      userIsUse: '', //是否启用通联支付
      isShowBusinessTwoType:false, //是否显示二级分类
      maxTime:{
        disabledDate(time){
          return time.getTime()>Date.now();
        }
      },
      is_show_order_class:true,
    };
  },
  mounted() {
    this.detail.shop_id = this.$route.query.detail.shop_id;
    this.detail.shop_name = this.$route.query.detail.shop_name;
    this.detail.uid = this.$route.query.detail.id;
    this.detail.customer_id = this.$route.query.detail.id;
    this.detail.consignee = this.$route.query.detail.customer_name;
    this.getOptions();
  },
  methods: {
    business_type(e){
      if(e==2){
        this.isShowBusinessTwoType = true;
        this.detail.business_two_type = 2;
      }else{
        this.isShowBusinessTwoType = false;
        this.detail.business_two_type = "";
      }
    },
    changeRefundType(e){

      this.detail.refund_type_plus =  e == '101.03' ? '2' : '1';
      // this.detail.refund_type_plus = this.userIsUse == '1' ? '1' : '2';
      console.log('收款方式'+this.detail.refund_type_plus);
      this.getRefundModeList(this.detail.refund_type_plus);

    },

    changeRefundMode(e){
      this.goods.forEach((item,index) => {
        item.refund_mode = '';
      })


      this.getRefundModeList(e);
    },

    getRefundModeList(mode){
      // console.log(mode);
      // return false;
      refundMode({ refundMode: mode }).then(res => {
        this.options.refundMode = res.data.list;
      });
    },

    // 获取选项
    getOptions() {
      // 组织
      shopMarket({ shopId: this.detail.shop_id }).then(res => {
        this.options.market = res;
        this.detail.sales_organization = this.options.market[0].marketKingdeeCode;
        this.detail.sales_name = this.options.market[0].marketName;
        this.detail.settlement_organization = this.options.market[0].marketKingdeeCode;
        this.detail.settlement_name = this.options.market[0].marketName;
      });
      // 促销政策
      saleList().then(res => {
        this.options.sales_policy = res.data.list;
      });
      // 该门店员工
      staffList({ page: 1, limit: 100000, shopId: this.detail.shop_id }).then(
        res => {
          this.options.staff = res.list;
        }
      );
      // 该门店详情
      shopDetail({ shopId: this.detail.shop_id }).then(res => {
        this.detail.bank_number = res.bankCard;
        this.detail.market_id = res.marketId;
        this.detail.market_name = res.marketName;
        this.isSichuan = res.isSichuan;
        this.shopType = res.shopType;
        if (this.shopType == 1) {
          this.getSecondShop(this.$route.query.detail.telephone);
          this.detail.order_class = "1";
        }else{
          this.detail.order_class = "0";
          this.is_show_order_class = false;
        }
      });
      // 收款方式列表
      refundMode({ refundMode: this.detail.refund_type_plus }).then(res => {
        this.options.refundMode = res.data.list;
      });

      // 获取登录人的信息
      getUserInfo().then(res =>{
        console.log('个人信息返回值：'+res.shop_type);
        this.userShopType = res.shop_type;
        this.userIsUse = res.is_use;
      });
    },
    // 获取第二个门店
    getSecondShop(telephone) {
      getTsShopList({ telephone: telephone }).then(res => {
        this.options.referral_shop_id = res.data;
        this.detail.referral_shop_id = res.data.length
          ? this.options.referral_shop_id[0].shop_id
          : "";
        this.detail.referral_shop_name = res.data.length
          ? this.options.referral_shop_id[0].shop_name
          : "";
        // console.log(this.detail.referral_shop_id)
      });
    },
    // 选择第二个门店
    changeReferralShop(e) {
      this.options.referral_shop_id.forEach(item => {
        if (e == item.shop_id) {
          this.detail.referral_shop_name = item.shop_name;
        }
      });
    },
    // 选择转开单人
    changeSale(e) {
      this.options.staff.forEach(item => {
        if (e == item.id) {
          this.detail.sale_name = item.name;
        }
      });
    },
    // 选择销售组织
    changeSales(e) {
      this.options.market.forEach(item => {
        if (e == item.marketKingdeeCode) {
          this.detail.sales_name = item.marketName;
        }
      });
    },
    // 选择发货组织
    changeSettlement(e) {
      this.options.market.forEach(item => {
        if (e == item.marketKingdeeCode) {
          this.detail.settlement_name = item.marketName;
        }
      });
    },
    // 选择体验人
    changeExperiencc(e) {
      this.options.staff.forEach(item => {
        if (e == item.id) {
          this.detail.experience_name = item.name;
        }
      });
    },
    // 选择外联人
    changeOutreahc(e) {
      this.options.staff.forEach(item => {
        if (e == item.id) {
          this.detail.outreahc_name = item.name;
        }
      });
    },
    // 选择转介绍人
    changeIntroduction(e) {
      this.options.staff.forEach(item => {
        if (e == item.id) {
          this.detail.introduction_name = item.name;
        }
      });
    },
    // 商品操作
    handleProduct(type) {
      switch (type) {
        case "add":
          this.goods.push(new Goods());
          break;
      }
    },
    changeTreatment() {
      this.detail.referral_shop_id = "";
      this.detail.referral_shop_name = "";
    },
    // 方式选择前
    selectFocus() {
      if (!this.detail.shop_id)
        return this.$message.warning("请先选择店铺哦！");
    },
    // 收款方式
    handleChange(value, index) {

      this.goods[index].business_two_type ='';
      this.detail.gift_amount = this.sum(this.goods);
      this.detail.pay_amount = this.sum(this.goods);
      this.detail.shop_transfer_amount = this.sum(this.goods);
      // console.log(this.goods[index]);return false;
      // this.goods[index].bank_number = this.detail?.bank_number;
      this.presentChange();
      this.payChange();
      this.storeTransferChange();
      let turnShop = 0;
      this.goods.forEach(item => {
        if (item.refund_mode == "06") {
          turnShop += 1;
          this.detail.referral_shop_id = "";
          this.detail.referral_shop_name = "";
        }
      });
      if (turnShop > 0) {
        this.turnShop = true;
      } else {
        this.turnShop = false;
      }
    },
    // 赠送金额合计
    presentChange() {
      let sum1 = 0;
      this.goods.forEach(item => {
        sum1 += item.gift_price ? item.gift_price : 0;
      });
      this.detail.gift_amount = sum1;
    },
    //店转金额合计
    storeTransferChange(){
      let sum1 = 0;
      this.goods.forEach(item => {
        sum1 += item.shop_transfer_price ? item.shop_transfer_price : 0;
      });
      this.detail.shop_transfer_amount = sum1;
    },
    // 本金金额合计
    payChange(value) {
      let sum1 = 0;
      this.goods.forEach(item => {
        sum1 += item.pay_price ? item.pay_price : 0;
      });
      this.detail.pay_amount = sum1;
    },


    sum(arr) {
      var s = 0;
      for (var i = arr.length - 1; i >= 0; i--) {
        s += arr[i].pay_price;
      }
      return s;
    },
    // 删除单商品
    clickDel(row,index) {
      this.goods.splice(index,1)
      this.handleChange();
    },

    submitForm(formName) {
      // this.detail.goodsList = this.goods.filter(val => val.id);
      this.detail.goodsList = this.goods
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.checking();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    checking() {

      // console.log(this.detail.goodsList);return false;
      if (this.detail.goodsList == 0) {
        this.$message({
          message: "请添加订单明细",
          type: "error"
        });
      } else {
        let pay_price = 0;
        let gift_price = 0;
        let shop_transfer_price = 0;
        this.detail.goodsList.forEach(item => {
          if (!item.pay_price && item.pay_price != 0) {
            pay_price += 1;
          }
          if (!item.gift_price && item.gift_price != 0) {
            gift_price += 1;
          }
          if(!item.shop_transfer_price && item.shop_transfer_price != 0){
            shop_transfer_price += 1;
          }
          item["data_business_type"] = item.business_type;
          item["data_business_two_type"] = item.business_two_type;
          item["data_sales_policy"] = this.detail.sales_policy;
          item["descs"] = "收款";
        });
        if (pay_price > 0 || gift_price > 0 || shop_transfer_price >0) {
          this.$message({
            message: "请完善收款金额明细",
            type: "error"
          });
        } else {
          this.detail.total_amount =
            this.detail.pay_amount + this.detail.gift_amount + this.detail.shop_transfer_amount;
          this.hint("添加");
        }
      }
    },
    // 提示
    hint(text) {
      this.$confirm(`确定${text}此收款订单吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 提交
    async confirm() {
      const loading = this.$loading({
        lock: true,
        text: "提交中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      try {
        const res = await refundOrder({ ...this.detail });
        loading.close();
        this.$message({
          type: "success",
          message: res.msg
        });

        console.log(res.msg);
        // this.back();

        if(res.msg == '创建成功!'){
          this.$router.push({ name: "待支付列表" });
        }else{
          this.back();
        }
        //
      } catch (e) {
        loading.close();
      }
    },
    // 返回
    back() {
      const query = {
        detail: this.$route.query.detail,
        id: this.$route.query.detail.id,
        activeName: "second"
      };
      this.$router.push({ name: "客户详情", query });
    }
  }
};
</script>

<style lang="scss" scoped>
.return {
  position: fixed;
  right: 20px;
  top: 71px;
  // background: #fff;
  z-index: 999;
  .backImg {
    margin-right: 14px;
    width: 32px;
    height: 100%;
    cursor: pointer;
    vertical-align: middle;
  }
}
.btn {
  margin: 20px 0 50px 0px;
}
.btnBox {
  width: 100%;
  text-align: center;
}
.mainBtn {
  margin: 30px 0 0 80px;
}

.phoneTip {
  font-size: 12px;
  color: #999;
}
.formBox {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 33%;
  }
}

.center {
  width: 100% !important;
}
</style>
